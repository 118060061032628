
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      username: 'demo@flow-solutions.com',
      password: 'Insight2020'
    };
  }, // data
  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['isAuthenticated', 'getLocale'])
  }, // computed
  async created() {
    if (!this.isAuthenticated) {
      const { username, password } = this;
      let response = await this.authenticate({ username, password });
      await this.fetchProfile();

      const storedUserId = localStorage.getItem('userID');
      if (storedUserId !== this.profile?.userID) {
        localStorage.removeItem('storeCodes');
      }
      localStorage.setItem('userID', this.profile.userID);
      let stores = localStorage.getItem('storeCodes');
      if (stores !== null) {
        this.setStoreCodes({
          storeCodes: localStorage.getItem('storeCodes').split(',')
        });
      } else {
        this.setStoreCodes({ storeCodes: this.profile.stores.split(',') });
        localStorage.setItem('storeCodes', this.profile.stores);
      }
      this.setStartingPeriod({
        startingPeriod: this.profile.defaultDateFilter?.startDate
      });
      this.setEndingPeriod({
        endingPeriod: this.profile.defaultDateFilter?.endDate
      });
      this.setStartingCompared({
        startingCompared: this.profile.defaultDateFilter?.compareStartDate
      });
      this.setEndingCompared({
        endingCompared: this.profile.defaultDateFilter?.compareEndDate
      });
      this.setRetailCalendar({
        dates: this.profile?.custom445Calendar
          ? this.profile?.custom445Calendar
          : {}
      });
      this.setCustomCalenderFlag({
        value: this.profile.defaultDateFilter?.useCustomCalender
      });
      localStorage.setItem(
        'startingPeriod',
        this.profile.defaultDateFilter?.startDate
      );
      localStorage.setItem(
        'endingPeriod',
        this.profile.defaultDateFilter?.endDate
      );
      localStorage.setItem(
        'startingCompared',
        this.profile.defaultDateFilter?.compareStartDate
      );
      localStorage.setItem(
        'endingCompared',
        this.profile.defaultDateFilter?.compareEndDate
      );
      localStorage.setItem(
        'useCustomCalender',
        this.profile.defaultDateFilter?.useCustomCalendar || false
      );
    }
    if (this.isAuthenticated) {
      await this.$router.push({ name: 'home' }).catch(_ => {});
    }
  }, // created
  methods: {
    ...mapActions('user', ['authenticate', 'fetchProfile']),
    ...mapMutations('user', ['setLocale']),
    ...mapMutations('filter', [
      'setStoreCodes',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      'setRetailCalendar'
    ])
  } // methods
}; // export default
